import React from 'react';
import { useState, useEffect, Suspense } from 'react';
import './serviceListing.css';
import Service from './Service';
import SearchBar from './SearchBar';
import AddService from './AddService';
import TypeTagInput from './TypeTagInput';


function ServiceListing ({services,handleAddNew = f => f}) {
    
    //const [clicked, setClick] = useState(false);
        const [filteredServices,setFilteredServices] = useState();
        const [filter,setFilter] = useState();

        function Loading(){
            return <h1>🌀Loading...</h1>
        }        

        function handleFilterService (f) {
            
            f = f.toLowerCase();
            setFilter(f);
            console.log(f);
            console.log("filtered types: ", services.filter(service=> service.type.join(" ").toLowerCase().includes(f)));
            console.log("filtered names: ", services.filter(service=> service.serviceName.toLowerCase().includes(f)));
            console.log("Allans Mowing: ", services[0].serviceName);
            if (f==="") setFilteredServices(services); else {
                const filterTypes = services.filter((service)=> service.type.join(" ").toLowerCase().includes(f))
                const filterNames = services.filter((service)=> service.serviceName.toLowerCase().includes(f))
                const filterArray = filterTypes.concat(filterNames);
                
                console.log("filtered types: ", services.filter(service=> service.type.join(" ").toLowerCase().includes(f)));
                console.log("filtered names: ", services.filter(service=> service.serviceName.toLowerCase().includes(f)));    
                
                setFilteredServices(filterArray);
                
            
        }
        }   

        function handleFilterClick (f) {
            filter !== f ? handleFilterService(f)
            : handleFilterService("");            
        }

        return (
            <>
                <SearchBar handleChange={(f)=>handleFilterService(f)}/>
                <TypeTagInput label='filter' del={true} onSelect={(f)=>handleFilterClick(f)} key={1} tagList={[filter]}/>
                <AddService handleAddNew={(returnObject)=>{
                    handleAddNew(returnObject);
                }}
                />
                <Suspense fallback={<Loading />}>
                {filteredServices ? 
                filteredServices.map((service) => <Service handleServiceTagClick={(f)=>handleFilterService(f)} key={service.id+Math.random()} service={service} />) 
                : services.map((service) => <Service handleServiceTagClick={(f)=>handleFilterService(f)} key={service.id+Math.random()} service={service} />) }
                </Suspense>
                
            </>
        );
}

export default ServiceListing;