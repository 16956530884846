import React from 'react';
import './tagButton.css';

function TagButton ({
    option,
    selected,
    del,
    tag,
    id,
    onSelectTag = f => f,
    onDeleteTag = f => f,
}) {
    const deleteTag = (del===true) ? <div onClick={()=>onDeleteTag(tag)} className="delete">X</div> : "";
    return (
    tag ? <button type="button" className={`tagButton ${selected ? " selected" : option ? " option" :""}` } onClick={()=>{onSelectTag(tag);console.log(tag)}} id={id}>{tag}<div>{deleteTag}</div></button> : <></>
    )
}

export default TagButton;