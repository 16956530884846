import React, {useState} from 'react';
import TagButton from './TagButton';
import './typeTagInput.css'

function TypeTagInput ({label,del,visible, tagList,onSelect = f => f}) {
    const [selected,setSelected] = useState([]);
    const [options,setOptions] = useState(tagList);
    
    return (
        <>
        <div className={"typeTagInputWrapper"}>
        <div className='typeTagInput'>
        <div className='typeTagInputLabel' >{label}</div> 
        <div className={"tagCloud "+visible}>
                {(tagList != [""]) ? tagList.map((tag)=>
                <TagButton option={false} del={del} selected={true} onSelectTag={(f)=>onSelect(f) }
                    tag={tag} key={Math.random()*100+tag} id={tag}/>
                    )    : <>No</>
                }
            </div> 
        </div>
        </div>
    </>
    )
}

export default TypeTagInput;