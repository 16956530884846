import {useState, useEffect} from 'react';
import ServiceListing from "../components/ServiceListing";
const REMOTE_HOST = "http://api.circlesupports.com/db/people:3000" //156.67.219.213
const HOST = REMOTE_HOST//"http://localhost:3004";


const ServiceDirectory = () => {

    const [services, setServices] = useState([]);
    const [numberOfServices, setNumberOfServices] = useState([]);
    
    function addService(serviceName,phone,serviceTypes,website,description,reviews) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({     "serviceName": serviceName,
                        "phone": phone,
                        "type": serviceTypes,
                        "website": website,
                        "description": description,
                        "reviews":reviews})
        };
        fetch(`${HOST}/services`, requestOptions)
            .then(response => console.log(response))
            .then(data => console.log("data",data,"data.id",data) );
            setNumberOfServices(numberOfServices+1);
    }//response.json())

    useEffect(()=> {
        fetch(`${HOST}/services`)
            .then(res => {
                return res.json()
            })
            .then(data=>{
                setServices(data);
                setNumberOfServices(data.length);
            })
    },[numberOfServices]);
    
    return (
        <>
        <h2>Service Listing</h2>
        <ServiceListing handleAddNew={({serviceName,phone,serviceTypes,website,description,reviews})=>{
            addService(serviceName, phone, serviceTypes,website,description,reviews);
            console.log(serviceName,phone,serviceTypes,website,description,reviews);
        }} services={services} />
        </>
    );
  };
  
  export default ServiceDirectory;