import React,{useState} from 'react';
import StarRating from './StarRating';


function Review ({reviewerName,review,rating,date}) {
    
    return (
        <>
        <div >
            <h3>{reviewerName}</h3>
            <p>{review}</p>
            <StarRating totalStars={5} rating={rating}/>
            <p>{date}</p>
        </div>
        </>
    );
}

export default Review;