import React from 'react';
import './AppHeaderAndFooter.css';
import './MenuButton.css';
import logo from '../logo.png'

class AppFooter extends React.Component {
    render (){
        return (
        <div className="appFooter">
            <hr></hr>
            
            
        </div>
        )
    };
}

export default AppFooter;