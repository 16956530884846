import './App.css';
import { ReactDOM } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from './pages/Layout';
import './pages/layout.css';
import Plan from './pages/Plan';
import Coordinate from './pages/Coordinate';
import Document from './pages/Document';
import Get from './pages/Get';
import Learn from './pages/Learn';
import ServiceDirectory from './pages/ServiceDirectory.js';
import menuItems from './data/menuItems';
import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter';
import MenuButton from './components/MenuButton';
import { Outlet, Link } from "react-router-dom";


const baseUrl = "/";

function App() {
    
    return (
      <BrowserRouter>
           
            <div id="sidebar">
        
        <AppHeader/>
        
        <div className="wrapper">
        <div className="menu">
          <ul className="menu">
            
            <Link className="menuItem" to="plan">Plan</Link>
            <Link className="menuItem" to="coordinate">Coordinate</Link>
            <Link className="menuItem" to="document">Document</Link>
            <Link className="menuItem" to="get">Get</Link>
            <Link className="menuItem" to="learn">Learn</Link>
          </ul>
        </div>
        </div>
      </div>
      <div id="detail"></div>
      <div className='pageContainer'>
      <Outlet />
      </div>
      <Routes>
            
                <Route path={baseUrl + "plan"} element={<Plan/>}/>
                <Route path={baseUrl + "coordinate"} element={<Coordinate/>}>
                  <Route path={baseUrl + "coordinate/service-directory"} element={<ServiceDirectory/>}/>
                </Route>
                <Route path={baseUrl + "document"} element={<Document/>}/>
                <Route path={baseUrl + "get"} element={<Get/>}/>
                <Route path={baseUrl + "learn"} element={<Learn/>}/>
                
            
            </Routes> 
            <AppFooter/>
            {/*<div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload again.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      
      </header>
    </div>*/}



</BrowserRouter>
    );
}
 

export default App;
