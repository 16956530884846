import React from 'react';
import { useState } from 'react';
import './MenuButton.css';

function MenuButton (props) {
    const [clicked, setClick] = useState(false);
    console.log(props);
    
    function buttonClicked () {
        setClick((!clicked)?true:false);
    }
    
        return (
            <>
            {props.time?<a href={props.link} target="_blank" rel="noopener noreferrer" ><button onClick={buttonClicked} className="menuButton" ><span className="time">{props.time}<span className='text'>mins</span></span>{props.title}</button></a>:<a href={props.link} target="_blank" rel="noopener noreferrer" ><button onClick={buttonClicked} className="menuButton" >{props.title}</button></a>}
    
            {props.subItems?.map(item => {return <a target="_blank" href={item.link} > <button className={clicked ? "subMenu visible menuButton" : "hidden menuButton"}> {item.title}</button></a>})}
            </>
        );
}

export default MenuButton;