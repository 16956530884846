import React,{useState, useEffect} from 'react';
import './addData.css';
import TagInput from './TagInput';

function Plus ({onClick=f=>f}) {
    return (
        <div ><button onClick={()=>onClick("clicked")} className="plus">+</button></div>
    )
}


function AddService ({handleAddNew = f => f}) {
    const [formShown,setFormShown] = useState(false);
    const [serviceName,setServiceName] = useState();
    const [phone,setPhone] = useState();
    const [serviceTypes,setServiceTypes] = useState([]);
    const [website,setWebsite] = useState();
    const [description, setDescription] = useState();
    
    const tagList=["Occupational Therapy","Cleaning","Yard Maintainance","Support Work", "Coordination","Recovery Coaching","Psychology","Psychiatrist","Counselling","Chiropractic","Massage","Art Therapy","Physiotherapy"];
    
    function handleSelectTag(f,array) {
        console.log("before service types is: ",array, "f is: ",f);
            console.log("is not array: ",!array.isArray);
            console.log("reduced array", array.reduce((acc,cur)=> cur == f ? true : acc,false));
            
                
                if (array.reduce((acc,cur)=> cur == f ? true : acc,false)) {
                    console.log("reduced array was true and filtered array: ", array.filter((s)=>s!=f))
                    setServiceTypes(array.filter((s)=>s!=f))
                    return array.filter((s)=>s!=f);
                } else {
                    array.push(f);
                    console.log("reduced array was false and array service types set to ", array);
                    setServiceTypes(array);
                    return array;
                }
            
        
    }

    return (
        <div className="addData">
        <Plus onClick={()=>setFormShown(!formShown)}/>
        {formShown ? 
            <form className="addData">
            <h3>Add New Service</h3> 
            <TagInput label='Service Type' onSelectTag={(f)=>{
                handleSelectTag(f,serviceTypes);
                console.log("service types was set:",serviceTypes);
            }} 
            tagList={tagList}/>
            <label htmlFor='serviceName'>Service Name:</label><br/>
            <input onChange={(e)=>setServiceName(e.target.value)}id='serviceName' type="text"/><br/>
            <label>Phone:</label><br/>
            <input onChange={(e)=>setPhone(e.target.value)} type="text"/><br/>
            <label>Website:</label><br/>
            <input onChange={(e)=>setWebsite(e.target.value)} type="text"/><br/>
            <label>Description:</label><br/>
            <div  className='textAreaWrapper'><textarea onChange={(e)=>setDescription(e.target.value)} rows={10} type="textarea"/><br/></div>
            <button onClick={()=>{{
                console.log("service types set as:", serviceTypes);
                const returnObject = {
                    serviceName:serviceName,
                    phone:phone,
                    serviceTypes: serviceTypes,
                    website:website,
                    description:description
                };
                setFormShown(false);
                handleAddNew(returnObject)}}
                } type="button" className="plus">Add</button>
        </form>
        : <div></div>}
        </div>
    )
}

export default AddService;