import MenuButton from "../components/MenuButton";
import './layout.css';

const Learn = () => {
    return (<>
        <div className="pageContainer">
            
            <MenuButton title="Intranet and Induction" link="https://mybetterlife108.sharepoint.com/sites/company/"/> 
            <MenuButton title="Boundaries and Professionalism" link="https://mybetterlife108.sharepoint.com/sites/company/SitePages/Boundaries.aspx"/> 
            <MenuButton title="Writing Case Notes" link="https://mybetterlife108.sharepoint.com/sites/company/SitePages/Note-Taking.aspx"/>
        <br/>
        </div>    
        </>
        
    
        );


  };
  
  export default Learn;