import MenuButton from "../components/MenuButton";
import './layout.css';

const Plan = () => {
    return (<>
    <div className="pageContainer">
        
        <MenuButton title="Services" time="2" link="https://circlesupports.com/service-agreement-form/"/>
        <MenuButton title="Groups" time="2" link="https://circlesupports.com/program-of-support-2/"/> 
        <MenuButton title="Safety and Wellbeing" time="15" link="https://circlesupports.com/safety-plan/"/> 
    </div>    
    </>
    

    );
    
    
  };
  
  export default Plan;