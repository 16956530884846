import React from 'react';
import './AppHeaderAndFooter.css';
import logo from '../logo.png'

class AppHeader extends React.Component {
    render (){
        return (
        <div className="appHeader">
            <img className="logo" alt="Circle Supports Logo" src={logo}></img>
            
        </div>
        )
    };
}

export default AppHeader;