import React from 'react';
import { useState } from 'react';
import './serviceListing.css';
import TypeTagInput from './TypeTagInput';
import Review from './Review';

function Service ({service,handleServiceTagClick = f => f}) {
    const [clicked, setClicked] = useState(false);
    
    
        return (
            <>
            {clicked ? 
            <div onClick={()=>setClicked(!clicked)} className="serviceContainer clickedContainer">
                        <table>
                        <tr>
                        <th>Name</th>
                        <th>Type</th>
                        </tr>
                        <tr>
                        <td>
                                
                            <div className='service serviceName clicked' onClick={()=>
                                    setClicked(!clicked)
                                    } ><p >{service.serviceName}</p></div>
                        
                        </td>
                        <td>                                    
                            <TypeTagInput visible={clicked}  del={false} onSelect={(f)=>handleServiceTagClick(f)} key={1} tagList={service.type}/>
                        </td>
                        </tr>
                        </table>

                        <div className='service serviceDescription'><h3>Description</h3><p >{service.description}</p></div>    
                        <h3>Contact</h3>
                        <div className='service servicePhone'><a href={`tel:${service.phone}`}>{service.phone}</a></div>
                        <div className='service serviceWebsite'><a href={service.website}>{service.website}</a></div>
                        <div className="reviews" >
                            
                        <div>{service.reviews ? <h3 >Reviews</h3> : <></>}</div>
                        <hr></hr>
                        <div >
                                {service.reviews ? service.reviews.map(({reviewerName,review,rating,date})=><Review reviewerName={reviewerName} key={reviewerName+date} review={review} rating={rating} date={date} /> ): <></>}
                        </div>
                        </div>
            </div> 
            : <div className="serviceContainer">
                <div className='service serviceName' onClick={()=>setClicked(!clicked)} ><p >{service.serviceName}</p></div>
                
            </div> }      
            </>
        );
}

export default Service;

/* name : "Allans Mowing",
type: "Lawns and Maintainance",
phone : "0455363523",
website: "http://allansMowing.com.au",
description: "This service started in the 1980s and has been running ever since. Great Guy Allan. Great guy for sure",
starRating: 5,
reviews: [
    {
    name: "Eric",
    review: "What a guy that guy is",
    rating: 5,
    date: "1/3/23"
    }, */