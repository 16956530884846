import MenuButton from "../components/MenuButton";
import './layout.css';


const Document = () => {
    return (<>
        <div className="pageContainer">
           
            <MenuButton title="Timesheets" link="https://tsheets.intuit.com/"/> 
            <MenuButton title="Incident" time="10" link="https://circlesupports.com/incident/"/> 
        </div>    
        </>
        
    
        );
    

  };
  
  export default Document;