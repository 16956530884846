import React from 'react';
import './searchBar.css';

function SearchBar ({handleChange = f => f}) {
   
    
   
    return (
        <form>
        <input onChange={(e)=>handleChange(e.target.value)} className="search service" placeholder='Search for...Name...Location...Service Type' type="search"></input>
        </form>
    );
}

export default SearchBar;