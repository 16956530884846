import AppHeader from '../components/AppHeader'
import MenuButton from '../components/MenuButton';
import { Outlet, Link } from "react-router-dom";

import './layout.css';


function Layout() {
  return (
    <>
      
    </>

  );
}

export default Layout;