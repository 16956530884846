import MenuButton from "../components/MenuButton";
import './layout.css';
import ServiceDirectory from "./ServiceDirectory";
import {
    BrowserRouter as Router, Link,
    Routes, Route, BrowserRouter, Outlet
} from 'react-router-dom'



const Coordinate = () => {
    return (
        <> 
        <div className="pageContainer">
            
            
            
               <MenuButton title="Check Budget" time="10"  link="https://circlesupports.com/budget-check/"/>
               <MenuButton title="8 week Review" time="30" link="https://mybetterlife108.sharepoint.com/:w:/s/company/ESLAA_ecH1ZNoJkgHIyulMMBOGoBW5eWlsOyCUXFsnaw_w?e=F2HT0I/"/>
               <MenuButton title="9 MonthReview" time="30" link="https://mybetterlife108.sharepoint.com/:w:/s/company/EQHY7QUXiTlEm77Ymf1dnuABqlC_LWWOhSaslFzWy9Balg?e=g4kGQu"/>
               <MenuButton title="Support Letter Template" time="30" link="https://mybetterlife108.sharepoint.com/:w:/s/company/EWQF46CG03xCisWLlXxaaY0ByMLN0gk_BySTU3DrC9UCnw?e=HD2Ao0"/>
        </div>
        </>
        
    
        );
  };
  
  export default Coordinate;
