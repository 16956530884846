import React,{useState} from 'react';
import {FaStar} from 'react-icons/fa';

const createArray = length => [...Array(length)];

const Star = ({selected=false,onSelect=f=>f}) => (
    <FaStar color={selected ? "red" : "grey"} onClick={onSelect}/>
)

function StarRating ({totalStars = 5, rating}) {
    const [selectedStars,setSelectedStars] = useState(rating);
    return (
        <>
            {createArray(totalStars).map((n,i)=>(
                <Star 
                    key={i} 
                    selected={selectedStars > i}
                    onSelect={()=>setSelectedStars(i+1)}
                />
            ))}
            <p>
                {selectedStars} of {totalStars} stars
            </p>
            
        </>
    );
}

export default StarRating;