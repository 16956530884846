
import MenuButton from "../components/MenuButton";
import './layout.css';

const Get = () => {
    return (<>
        <div className="pageContainer">
            
            <MenuButton title="Consent - Circle" time="1" link="http://circlesupports.com/consent"/>
            <MenuButton title="Consent - Ndis" time="5" link="https://www.ndis.gov.au/about-us/policies/access-information/consent-forms"/> 
            <br/>
        </div>    
        
        </>
    
        );
  };
  
  export default Get;


  