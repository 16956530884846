import React, {useState} from 'react';
import TagButton from './TagButton';
import './tagInput.css'

function TagInput ({label,tagList,onSelectTag = f => f}) {
    const [selected,setSelected] = useState([]);
    const [options,setOptions] = useState(tagList);


    function flipSelector(tag) {
            
            if (selected.includes(tag)) { 
                setOptions(options.concat(tag));
                setSelected(selected.filter((s)=>s!==tag));
            } else {
                setSelected(selected.concat(tag));
                setOptions(options.filter((o)=>o!==tag));
                
        }
    }
    
    
    
    return (
        <div className="tagInputWrapper">
        <div className='tagInput'>
        <div className='tagInputLabel' >{label}</div> 
        <div className="tagCloud">
                {selected.map((tag)=>
                <TagButton option={false} selected={true} del={false} onSelectTag={(f)=>{
                    flipSelector(f);
                    onSelectTag(tag) 
                }}
                    tag={tag} id={Math.random()*100}/>
                    )
                    
                    
                }
                <br/>
                {options.map((tag)=>
                <TagButton option={true} selected={false} del={false} onSelectTag={(f)=>{
                    flipSelector(f);
                    onSelectTag(tag) 
                    }}
                    tag={tag} id={tag} key={Math.random()*1000}/>
                    )
                }
            
            </div> 
        </div>
        </div>
        
    )
}

export default TagInput;